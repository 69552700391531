import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Container from '@components/Container'
import { ButtonLink } from '@components/ButtonLink'
import { useTranslation } from 'react-i18next'
import SEO from '@components/SEO'
import { css } from '@emotion/react'

const NotFoundPage = () => {
	const { t } = useTranslation()

	return (
		<Container title={t('not_found.title')}>
			<SEO title={t('not_found.title')} keywords={[]} />
			<h1>{t('not_found.title')}</h1>
			<img
				src='/404.svg'
				alt={t('not_found.title')}
				css={css`
					width: 300px;
					max-width: 100%;
				`}
			/>
			<p>{t('not_found.description')}</p>
			<ButtonLink to={'/'}>{t('back_to_home')}</ButtonLink>
		</Container>
	)
}

export default NotFoundPage

export const query = graphql`
	query NotFound($language: String!) {
		...TranslationFragment
	}
`
